// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ako-to-robime-js": () => import("./../../../src/pages/ako-to-robime.js" /* webpackChunkName: "component---src-pages-ako-to-robime-js" */),
  "component---src-pages-big-data-js": () => import("./../../../src/pages/big-data.js" /* webpackChunkName: "component---src-pages-big-data-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-mobilne-aplikacie-js": () => import("./../../../src/pages/mobilne-aplikacie.js" /* webpackChunkName: "component---src-pages-mobilne-aplikacie-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-ochrana-osobnych-udajov-js": () => import("./../../../src/pages/ochrana-osobnych-udajov.js" /* webpackChunkName: "component---src-pages-ochrana-osobnych-udajov-js" */),
  "component---src-pages-outsource-js": () => import("./../../../src/pages/outsource.js" /* webpackChunkName: "component---src-pages-outsource-js" */),
  "component---src-pages-referencie-doku-js": () => import("./../../../src/pages/referencie/doku.js" /* webpackChunkName: "component---src-pages-referencie-doku-js" */),
  "component---src-pages-referencie-drivelog-js": () => import("./../../../src/pages/referencie/drivelog.js" /* webpackChunkName: "component---src-pages-referencie-drivelog-js" */),
  "component---src-pages-referencie-gyg-js": () => import("./../../../src/pages/referencie/gyg.js" /* webpackChunkName: "component---src-pages-referencie-gyg-js" */),
  "component---src-pages-referencie-helske-js": () => import("./../../../src/pages/referencie/helske.js" /* webpackChunkName: "component---src-pages-referencie-helske-js" */),
  "component---src-pages-referencie-js": () => import("./../../../src/pages/referencie.js" /* webpackChunkName: "component---src-pages-referencie-js" */),
  "component---src-pages-referencie-kindergeld-js": () => import("./../../../src/pages/referencie/kindergeld.js" /* webpackChunkName: "component---src-pages-referencie-kindergeld-js" */),
  "component---src-pages-referencie-piqipi-js": () => import("./../../../src/pages/referencie/piqipi.js" /* webpackChunkName: "component---src-pages-referencie-piqipi-js" */),
  "component---src-pages-referencie-realitat-js": () => import("./../../../src/pages/referencie/realitat.js" /* webpackChunkName: "component---src-pages-referencie-realitat-js" */),
  "component---src-pages-referencie-roomassistant-js": () => import("./../../../src/pages/referencie/roomassistant.js" /* webpackChunkName: "component---src-pages-referencie-roomassistant-js" */),
  "component---src-pages-referencie-tasty-js": () => import("./../../../src/pages/referencie/tasty.js" /* webpackChunkName: "component---src-pages-referencie-tasty-js" */),
  "component---src-pages-referencie-tjl-app-js": () => import("./../../../src/pages/referencie/tjl-app.js" /* webpackChunkName: "component---src-pages-referencie-tjl-app-js" */),
  "component---src-pages-referencie-ultimuv-js": () => import("./../../../src/pages/referencie/ultimuv.js" /* webpackChunkName: "component---src-pages-referencie-ultimuv-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-success-outsource-js": () => import("./../../../src/pages/success-outsource.js" /* webpackChunkName: "component---src-pages-success-outsource-js" */),
  "component---src-pages-umela-inteligencia-js": () => import("./../../../src/pages/umela-inteligencia.js" /* webpackChunkName: "component---src-pages-umela-inteligencia-js" */),
  "component---src-pages-web-aplikacie-js": () => import("./../../../src/pages/web-aplikacie.js" /* webpackChunkName: "component---src-pages-web-aplikacie-js" */)
}

